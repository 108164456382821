<template>
  <div>
    <v-row>
      <vue-snotify></vue-snotify>
      <v-col cols="12" md="12">
        <v-card class="mt-2 mb-4">
          <v-toolbar color="primary">
            <v-row align="center">
              <v-col class="grow">
                <span style="color: white"> Información por RUT</span>
              </v-col>

              <v-btn @click="exportToExcel" color=" mr-2">
                <v-icon left> mdi-microsoft-excel </v-icon>
                Exportar Excel
              </v-btn>

              <v-btn @click="generateReport" color=" mr-2">
                <v-icon left> mdi-file-pdf-box </v-icon>
                Exportar PDF
              </v-btn>

            </v-row>
          </v-toolbar>
          <v-card class="mt-3">
            <v-row class="ml-3">
              <v-col cols="12" md="3" class="ml-3 mt-3">
                <v-select v-model="typePerson" no-data-text="No hay datos disponibles" :items="typesPerson"
                  item-text="value" item-value="value" prepend-icon="mdi mdi-text-account" :disabled="itemDisabled"
                  label="Tipos de personas *" persistent-hint>
                </v-select>
              </v-col>
              <v-col cols="12" md="2" class="ml-3 mt-3">
                <v-text-field prepend-icon="mdi-card-account-details" :counter="10" v-model="rut" :rules="rutRules"
                  placeholder="12345678-9" label="RUT *" type="text"></v-text-field>
              </v-col>
              <v-col cols="12" md="2" class="mt-4 ml-3">
                <template>
                  <v-container>
                    <v-menu v-model="menu2" :close-on-content-click="false" transition="scale-transition" offset-y
                      max-width="290px" min-width="auto">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field class="mt-0 pt-0" v-model="computedDateFormatted" label="Fecha inicial *"
                          persistent-hint prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
                      </template>
                      <v-date-picker v-model="start_date" no-title @input="menu2 = false"></v-date-picker>
                    </v-menu>
                  </v-container>
                </template>
              </v-col>
              <v-col cols="12" md="2" class="mt-4 ml-3">
                <template>
                  <v-container>
                    <v-menu v-model="menu3" :close-on-content-click="false" transition="scale-transition" offset-y
                      max-width="290px" min-width="auto">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field class="mt-0 pt-0" v-model="computedDateEndFormatted" label="Fecha final *"
                          persistent-hint prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
                      </template>
                      <v-date-picker v-model="end_date" :rules="dateRules" no-title
                        @input="menu3 = false"></v-date-picker>
                    </v-menu>
                  </v-container>
                </template>
              </v-col>
              <v-col cols="12" md="2" class="ml-3 mt-5">
              
                <v-btn color="primary" @click="getInformAccess()">
                  Buscar
                </v-btn>
              </v-col>
            </v-row>
          </v-card>
          <v-divider class="mb-3"></v-divider>
          <v-card-text>
            <v-row class="ml-15 pl-15 mt-5">
              <v-col cols="4">
                <p>
                  <strong class="mr-3">Total de Entradas :</strong>
                  {{ time_entry + late_check_in }}
                </p>
                <p>
                  <strong class="mr-3">Total de salidas: </strong>{{ departure_on_time + late_departure }}
                </p>
              </v-col>
              <v-col cols="4">
                <p>
                  <strong class="mr-3">Entradas en tiempo:</strong>
                  {{ time_entry }}
                </p>
                <p>
                  <strong class="mr-3">Entradas retrasadas</strong>{{ late_check_in }}
                </p>
              </v-col>
              <v-col cols="4">
                <p>
                  <strong class="mr-3">Salidas en tiempo:</strong>{{ departure_on_time }}
                </p>
                <p>
                  <strong class="mr-3">Salidas retrasadas::</strong>{{ late_departure }}
                </p>
              </v-col>
              <v-divider></v-divider>

            </v-row>
            <v-row style="text-align: center;"> <v-col cols="4">
                <p class="ml-2" v-if="person != ''"> <strong>RUT: </strong> {{ person.rut }}
                </p>
              </v-col>
              <v-col cols="4">
                <p class="ml-2" v-if="person != ''"> <strong>Nombre: </strong> {{ person.name + ' ' + person.last_name + ' ' +person.mother_last_name }}
                </p>
              </v-col>

              
              <v-col v-if="typePerson != '' && person != ''" cols="4">
                <strong>Tipo de persona:</strong> {{ typePerson }}
              </v-col>
            
            </v-row>
            
            <v-data-table :key="JSON.stringify(headers)" :headers="headers" :items="registers" :search="search"
              :footer-props="{
                itemsPerPageText: 'Filas por página',
                itemsPerPageAllText: 'todos',
              }" no-data-text="No hay datos disponibles" no-results-text="No hay datos disponibles">
              <template v-slot:[`item.id_action`]="{ item }">
                <span>{{ getActionById(item.id_action) }}</span>
              </template>
              <template v-slot:[`item.id_entrance`]="{ item }">
                <span>{{ getAccessById(item.id_entrance) }}</span>
              </template>
              <template v-slot:[`item.type_person`]>
                <span>{{ typePerson.value }}</span>
              </template>
              <template v-slot:[`item.date`]="{ item }">
                <span>{{ formatDateComplement(item.date) }}</span>
              </template>
              <template v-slot:[`item.time`]="{ item }">
                <span>{{ formatTime(item.date) }}</span>
              </template>
              <template v-slot:[`item.on_time`]="{ item }">
                <span v-if="item.on_time == 1" class="justify-center">
                  <v-chip class="ma-2" color="green" text-color="white">
                    En tiempo
                  </v-chip>
                </span>
                <span v-if="item.on_time == 0" class="justify-center">
                  <v-chip class="ma-2" color="red" text-color="white">
                    Retraso
                  </v-chip>
                </span>

                <span v-if="item.on_time == 2" class="justify-center">
                  <v-chip class="ma-2" color="orange" text-color="white">
                    Anticipado
                  </v-chip>
                </span>
              </template>

            </v-data-table>
            <div id="app">
              <vue-html2pdf :float-layout="true" :enable-download="true" :preview-modal="false"
                :paginate-elements-by-height="1400" filename="reporte" :pdf-quality="2" :manual-pagination="false"
                pdf-format="a4" @hasStartedGeneration="hasStartedGeneration()"
                :html-to-pdf-options="htmlToPdfOptions"
                @hasGenerated="hasGenerated($event)"
                ref="html2Pdf">
                <div slot="pdf-content" class="pl-10 pt-10">
                  <br /><br /><br />

                  <v-row>
                    <v-col cols="1"></v-col>
                    <v-col cols="2">
                      <img src="@/assets/logo.png" height="50px" width="200px" alt="Logo Luupa">
                    
                    </v-col>
                    <v-col cols="4"></v-col>
                    <v-col cols="5">
                      <v-list-item class="px-2">
                        <v-list-item-avatar>
                          <img :src="profilepic"
                         height="50px" width="200px" alt="Logo Institución"> </v-list-item-avatar>
                        <v-list-item-content style="padding-left:5px">
                          <v-list-item-title>
                            <h4>{{ institucionStore.getInstitution.name }}</h4>
                            <h5>{{ institucionStore.getInstitution.rut }}</h5>
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>

                  <v-row style="text-align:center">
                    <v-col cols="12"><strong>REPORTE DE ACCESOS POR TIPO DE PERSONA</strong>
                    </v-col>
                  </v-row>
                  

                  <v-row style="text-align:center;padding-left: 10px;padding-right: 10px;">
                    <v-col cols="3"><strong>Entradas en tiempo: </strong> {{ time_entry }}
                    </v-col>
                    <v-col cols="3"><strong>Entradas retrasadas: </strong> {{ late_check_in }}
                    </v-col>
                    <v-col cols="3"><strong>Salidas en tiempo: </strong> {{ departure_on_time }}
                    </v-col>
                    <v-col cols="3"><strong>Salidas retrasadas: </strong> {{ late_departure }}
                    </v-col>
                  </v-row>


                  <v-row style="margin-left: 10px;">
                    <v-col cols="4">
                      <p class="ml-2" v-if="person != ''"> <strong>RUT: </strong> {{ person.rut }}
                      </p>
                    </v-col>

                    <v-col cols="4"></v-col>

                    <v-col v-if="typePerson != ''" cols="4">
                      <strong>Tipo de persona:</strong> {{ typePerson }}
                    </v-col>

                    <v-col cols="12">
                      <p class="ml-2" v-if="person != ''"> <strong>Nombre: </strong> {{ person.name + ' ' +
                        person.last_name + ' ' + person.mother_last_name }}
                      </p>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="12">
                      <v-data-table style="text-align: center; width: 100%;padding-left: 20px;padding-right: 20px;"
                        :headers="headers" :items="registers" :search="search" hide-default-footer :footer-props="{
                          itemsPerPageText: 'Filas por página',
                          itemsPerPageAllText: 'todos',
                        }" no-data-text="No hay datos disponibles" no-results-text="No hay datos disponibles">
                        <template v-slot:[`item.id_action`]="{ item }">
                          <span>{{ getActionById(item.id_action) }}</span>
                        </template>
                        <template v-slot:[`item.id_entrance`]="{ item }">
                          <span>{{ getAccessById(item.id_entrance) }}</span>
                        </template>
                        <template v-slot:[`item.type_person`]>
                          <span>{{ typePerson.value }}</span>
                        </template>
                        <template v-slot:[`item.date`]="{ item }">
                          <span>{{ formatDateComplement(item.date) }}</span>
                        </template>
                        <template v-slot:[`item.time`]="{ item }">
                          <span>{{ formatTime(item.date) }}</span>
                        </template>
                        <template v-slot:[`item.on_time`]="{ item }">
                          <span v-if="item.on_time == 1" class="justify-center">
                  <v-chip class="ma-2" color="green" text-color="white">
                    En tiempo
                  </v-chip>
                </span>
                <span v-if="item.on_time == 0" class="justify-center">
                  <v-chip class="ma-2" color="red" text-color="white">
                    Retraso
                  </v-chip>
                </span>

                <span v-if="item.on_time == 2" class="justify-center">
                  <v-chip class="ma-2" color="orange" text-color="white">
                    Anticipado
                  </v-chip>
                </span>
                        </template>
                      </v-data-table></v-col>

                  </v-row>
                </div>
              </vue-html2pdf>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import axios from "axios";
import VueSnotify from "vue-snotify";
import SnotifyMixin from "@/mixins/DisplayNotificationMixin.vue";
import html2pdf from "html2pdf.js";
import VueHtml2pdf from "vue-html2pdf";
import { useUserStore } from "@/store/user";
import { useInstitutionStore } from "@/store/institution";

import * as XLSX from "xlsx";

export default {
  mixins: [SnotifyMixin],
  VueSnotify,
  components: {
    VueHtml2pdf,
  },
  setup() {
    const store = useUserStore();
    const institucionStore = useInstitutionStore();

    return { store, institucionStore };
  },

  data() {
    return {
      profilepic: "",
      imageUrl: 'https://apiluupa.klint.cl/image/logo.png', // Reemplaza con la URL real de la imagen
      imageSrc: '', // Aquí almacenaremos la imagen en base64
      visible: false,
      drawer: true,
      dialog: false,
      search: "",
      start_date: null,
      menu2: false,
      menu3: false,
      end_date: null,
      cont: 0,
      access: "",
      accesses: [],
      dates: ["", ""],
      typePerson: "",
      data: {},
      total_entrances: 0,
      total_exits: 0,
      rut: "",
      person: "",
      time_entry: 0,
      late_check_in: 0,
      departure_on_time: 0,
      late_departure: 0,

      typesPerson: [
        { value: "Funcionario" },
        { value: "Alumno" },
        { value: "Visita" },
      ],

      headers: [
        { text: "Fecha", value: "date" },
        { text: "Hora", value: "time" },
        { text: "Acción", value: "id_action" },
        { text: "Condición", value: "on_time" },
        { text: "Puerta", value: "id_entrance" },

      ],

      state: "",
      actions: [],
      registers: [],
      status: [],
      credencial_types: [],

      rutRules: [
        (v) => !!v || "El RUT es requerido",
        (v) =>
          (v && v.length <= 10) || "El RUT debe tener menos de 11 caracteres",
      ],
    };
  },
  watch: {
    menu(val) {
      val && setTimeout(() => (this.activePicker = "YEAR"));
    },

    date() {
      this.dateFormatted = this.formatDate(this.date);
    },
  },

  computed: {
    computedDateFormatted() {
      return this.formatDate(this.start_date);
    },
    computedDateEndFormatted() {
      return this.formatDate(this.end_date);
    },


    htmlToPdfOptions() {
      return {
        margin: 0,

        filename: "reporte_luupa.pdf",

        image: {
          type: "jpeg",
          quality: 0.98,
        },

        enableLinks: true,
        html2canvas: { scale: 2,useCORS: true },
       
      
      };
    },


  },

  mounted() {
    this.action = "Eliminar";
   
    this.getImage() 

    this.getAccess();
    this.getActions();
  },

  methods: {
    getImage() {
       URL.revokeObjectURL(this.profilepic);
      axios
        .get("/imagen/logo.png", { responseType: 'arraybuffer' }) // Indicamos que esperamos una respuesta de tipo arraybuffer
        .then(response => {
          const blob = new Blob([response.data], { type: response.headers['content-type'] });
          const blobUrl = URL.createObjectURL(blob);
          this.profilepic=blobUrl;

         
        })
        .catch(error => {
          console.error('Error al cargar la imagen:', error);
        });

        console.log("Finaliza" +  this.profilepic)
    },

    async fetchImageAndConvertToBase64() {
        try {
          const response = await fetch(this.imageUrl);
          const blob = await response.blob();
          const reader = new FileReader();
          reader.onload = () => {
            this.imageSrc = reader.result;
          };
          reader.readAsDataURL(blob);
        } catch (error) {
          console.error('Error al obtener la imagen:', error);
        }
      },
    getImagePath() {
      let url = axios.defaults.baseURL.split("api/")[0] + `image/logo.png`
      return url
    },

    formatDate(date) {
      if (!date) return null;
      const dateAux = new Date(date);
      const [year, month, day] = dateAux.toISOString().slice(0, 10).split("-");
      return `${day}/${month}/${year}`;
    },
    formatTime(date) {
      if (!date) return null;

      const [hour, min, sec] = date.split(" ")[1].split(':');
      return `${hour}:${min}:${sec}`;
    },

    parseDate(date) {
      if (!date) return null;

      const [day, month, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },



    formatDateComplement(date) {
      if (!date) return null;
      const [year, month, day] = date.split(" ")[0].split("-");
      return `${day}/${month}/${year}`;
    },

    save(date) {
      this.$refs.menu.save(date);
    },
    generateReport() {
      this.$refs.html2Pdf.generatePdf();
    },

    exportToExcel() {
      const worksheet = XLSX.utils.table_to_sheet(this.$refs.html2Pdf.$el);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet 1");
      XLSX.writeFile(workbook, "report.xlsx");
    },

    generateReportXLS() {
      // Obtén la referencia al contenedor del contenido del reporte
      const reportContent = this.$refs.html2Pdf;

      // Crea el objeto de configuración para html2pdf
      const config = {
        filename: "reporte.pdf",
        image: { type: "png" },
        html2canvas: { scale: 2 },
        margin: [0, 60, 0, 0], // Margen superior para dar espacio al encabezado de la imagen
        jsPDF: { format: "a4", orientation: "portrait" },
      };

      // Exporta el contenido del reporte como PDF utilizando html2pdf
      html2pdf().from(reportContent).set(config).save();
      //  this.$refs.html2Pdf.generatePdf();
    },

    generateTableContent() {
      const tableRows = [];

      // Agrega las filas de la tabla
      for (let i = 0; i < this.credencials.length; i++) {
        const row = [];
        // Agrega las celdas de la fila
        for (let j = 0; j < this.headers.length; j++) {
          const key = this.headers[j].value;
          const value = this.credencials[i][key];
          row.push(value);
        }
        tableRows.push(`<tr><td>${row.join("</td><td>")}</td></tr>`);
      }
      return `<table><thead><tr><th>${this.headers
        .map((header) => header.text)
        .join("</th><th>")}</th></tr></thead><tbody>${tableRows.join(
          ""
        )}</tbody></table>`;
    },

    exportTableToPdf() {
      const tableContent = this.generateTableContent();
      const element = document.createElement("div");
      element.innerHTML = tableContent;

      html2pdf(element, {
        margin: 1,
        filename: "tabla.pdf",
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { dpi: 192, letterRendering: true },
        jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
      });
    },

    getAccessById(access_id) {
      let access = this.accesses.find((a) => a.id == access_id);
      return access.name;
    },
    getActionById(action_id) {
      let action = this.actions.find((a) => a.id == action_id);
      return action.name;
    },

    getActions() {
      axios
        .get("/action-index")
        .then((response) => {
          this.actions = response.data;
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification(
            "error",
            "Error",
            "Error al obtener las credenciales"
          );
        });
    },

    getStatus() {
      axios
        .get("/status-index")
        .then((response) => {
          this.status = response.data;
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification(
            "error",
            "Error",
            "Error al obtener los estados"
          );
        });
    },
    getAccess() {
      axios
        .get("/entrance-index")
        .then((response) => {
          this.accesses = response.data;
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification(
            "error",
            "Error",
            "Error al obtener los Accesos"
          );
        });
    },

    getInformAccess() {
      if (
        this.start_date != null &&
        this.end_date != null &&
        this.start_date > this.end_date
      ) {
        this.displayNotification(
          "error",
          "Error",
          "Lo sentimos no se puede hacer la búsqueda. La fecha inicial debe ser menor a la fecha final."
        );
        return;
      }
      switch (this.typePerson) {
        case "Funcionario":
          console.log("entra a buscar en oficial");
          this.data.rut = this.rut;
          this.data.idAccess = this.access;
          this.data.startDate = this.start_date;
          this.data.endDate = this.end_date;
          axios
            .post("/information-by-rut-official", this.data)
            .then((response) => {             
              this.registers = response.data.result;            
              this.person = response.data.person;
              this.time_entry = response.data.time_entry;
              this.late_check_in = response.data.late_check_in;
              this.departure_on_time = response.data.departure_on_time;
              this.late_departure = response.data.late_departure;
            })
            .catch((err) => {
              console.log(err, "error");
              this.displayNotification(
                "error",
                "Error",
                "Error al procesar la solicitud de reporte"
              );
            });
          break;
        case "Alumno":
          this.data.rut = this.rut;
          this.data.startDate = this.start_date;
          this.data.endDate = this.end_date;
          axios
            .post("/information-by-rut-student", this.data)
            .then((response) => {
              console.log("imprime resultado de busqueda");
              console.log(response.data.result);
              this.registers = response.data.result;
              this.person = response.data.person;
              this.time_entry = response.data.time_entry;
              this.late_check_in = response.data.late_check_in;
              this.departure_on_time = response.data.departure_on_time;
              this.late_departure = response.data.late_departure;
            })
            .catch((err) => {
              console.log(err, "error");
              this.displayNotification(
                "error",
                "Error",
                "Error al procesar la solicitud de reporte"
              );
            });
          break;
        case "Visita":
          this.data.rut = this.rut;
          this.data.idAccess = this.access;
          this.data.startDate = this.dates[0];
          this.data.endDate = this.dates[1];
          axios
            .post("/information-by-rut-visit", this.data)
            .then((response) => {              
              this.registers = response.data.result;           
              this.person = response.data.person;
              this.time_entry = response.data.time_entry;
              this.late_check_in = response.data.late_check_in;
              this.departure_on_time = response.data.departure_on_time;
              this.late_departure = response.data.late_departure;
            })
            .catch((err) => {
              console.log(err, "error");
              this.displayNotification(
                "error",
                "Error",
                "Error al procesar la solicitud de reporte"
              );
            });
          break;
      }
    },

    deleteItem(item) {
      console.log(item);
      let request = {
        id: item.id,
      };
      axios
        .post("/credential-destroy", request)
        .then(() => {
          this.displayNotification("success", "Éxito", "Credencial Eliminada");
          this.getCredencials();
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification(
            "error",
            "Error",
            "No se pudo eliminar la credencial"
          );
        });
    },
  },
};
</script>
