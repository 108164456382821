<template>
  <div>
    <v-row>
      <vue-snotify></vue-snotify>
      <v-col cols="12" md="12">
        <v-card class="mt-2 mb-4">
          <v-toolbar color="primary">
            <v-row align="center">
              <v-col class="grow">
                <span style="color: white">
                  Información de credenciales por Curso</span>
              </v-col>

            </v-row>
          </v-toolbar>
          <v-card class="mt-3">
            <v-row>
              <v-col cols="12" md="3" class="ml-3 mt-3">
                <v-select v-model="grade" no-data-text="No hay datos disponibles" :items="grades" item-text="name"
                  item-value="id" prepend-icon="mdi-alpha-c-circle" :disabled="itemDisabled" label="Cursos *"
                  persistent-hint>
                </v-select>
              </v-col>
              <v-col cols="12" md="2" class="ml-3 mt-5">
                <v-btn color="primary" @click="getInformAccess()">
                  Buscar
                </v-btn>
              </v-col>
            </v-row>
          </v-card>
          <v-divider class="mb-3"></v-divider>
          <v-card-text>


            <v-data-table :key="JSON.stringify(headers)" :headers="headers" :items="registers" :search="search"
              :footer-props="{
                itemsPerPageText: 'Filas por página',
                itemsPerPageAllText: 'todos',
              }" no-data-text="No hay datos disponibles" no-results-text="No hay datos disponibles">

              <template v-slot:[`item.name`]="{ item }">
                <span>{{ item.name + ' ' + item.last_name + ' ' + item.mother_last_name
                }}</span>
              </template>
              <template v-slot:[`item.credential`]="{ item }">
                <v-chip v-if="item.credential != null" class="ma-2 text-left" color="success" outlined>
                  <v-icon left> mdi mdi-credit-card-check-outline</v-icon>
                  Asignada
                </v-chip>
                <v-chip v-if="item.credential == null" class="ma-2 text-left" color="red" outlined>
                  <v-icon left> mdi-card-off </v-icon>
                  No definida
                </v-chip>
              </template>
              <template v-slot:[`item.statu`]="{ item }">
                <v-chip v-if="item.credential != null && item.credential.status_id == 1
                  " class="ma-2 text-left" color="success" outlined>
                  <v-icon left>mdi-state-machine</v-icon>
                  {{ getStatusById(item.credential.status_id) }}
                </v-chip>
                <v-chip v-if="item.credential != null && item.credential.status_id == 2
                  " class="ma-2 text-left" color="red" outlined>
                  <v-icon left> mdi-state-machine</v-icon>
                  {{ getStatusById(item.credential.status_id) }}
                </v-chip>
                <v-chip v-if="item.credential != null && item.credential.status_id == 3
                  " class="ma-2 text-left" color="primary" outlined>
                  <v-icon left>mdi-state-machine</v-icon>
                  {{ getStatusById(item.credential.status_id) }}
                </v-chip>
                <v-chip v-if="item.credential != null && item.credential.status_id == 4
                  " class="ma-2 text-left" color="amber accent-3" outlined>
                  <v-icon left>mdi-state-machine</v-icon>
                  {{ getStatusById(item.credential.status_id) }}
                </v-chip>
                <v-chip v-if="item.credential == null" class="ma-2 text-left" color="red" outlined>
                  <v-icon left> mdi-card-off </v-icon>
                  No definido
                </v-chip>
              </template>
              <template v-slot:[`item.start_date`]="{ item }">
                <spam v-if="item.credential != null">
                  {{ formatDate(item.credential.start_date) }}
                </spam>
                <v-chip v-if="item.credential == null" class="ma-2 text-left" color="red" outlined>
                  <v-icon left> mdi-card-off </v-icon>
                  No definida
                </v-chip>
              </template>
              <template v-slot:[`item.end_date`]="{ item }">
                <spam v-if="item.credential != null">
                  {{ formatDate(item.credential.end_date) }}
                </spam>
                <v-chip v-if="item.credential == null" class="ma-2 text-left" color="red" outlined>
                  <v-icon left> mdi-card-off </v-icon>
                  No definida
                </v-chip>
              </template>
            </v-data-table>
            <div id="app" ref="testHtml">
              <vue-html2pdf :float-layout="true" :enable-download="true" :preview-modal="false"
                :paginate-elements-by-height="1400" filename="reporte" :pdf-quality="2" :manual-pagination="false"
                pdf-format="a4" @hasStartedGeneration="hasStartedGeneration()" @hasGenerated="hasGenerated($event)"
                ref="html2Pdf">
                <div slot="pdf-content" class="pl-10 pt-10">
                  <br /><br /><br />
                  <v-row>
                    <v-col cols="3" class="pt-10"></v-col>
                    <v-col cols="7">Reporte de accesos atrasados del curso {{ grade_name }}
                    </v-col>
                    <v-col cols="2"></v-col>
                    <br /><br />

                    <v-col cols="1"></v-col>
                    <v-col cols="4">
                      <p>
                        Entradas atrasadas: {{ " " + this.total_entrances }}
                      </p>
                    </v-col>
                    <v-col cols="4">
                      <p>Salidas atrasadas: {{ " " + this.total_exits }}</p>
                    </v-col>
                    <v-col cols="3"></v-col>
                    <v-col cols="1"></v-col>
                    <v-col cols="10">
                      <v-data-table :headers="headers" :items="registers" :search="search" hide-default-footer
                        :footer-props="{
                          itemsPerPageText: 'Filas por página',
                          itemsPerPageAllText: 'todos',
                        }" no-data-text="No hay datos disponibles" no-results-text="No hay datos disponibles">
                        <template v-slot:[`item.name`]="{ item }">
                          <span>{{ item.name + ' ' + item.last_name + ' ' + item.mother_last_name
                          }}</span>
                        </template>
                        <template v-slot:[`item.credential`]="{ item }">
                          <v-chip v-if="item.credential != null" class="ma-2 text-left" color="success" outlined>
                            <v-icon left>
                              mdi mdi-credit-card-check-outline</v-icon>
                            Asignada
                          </v-chip>
                          <v-chip v-if="item.credential == null" class="ma-2 text-left" color="red" outlined>
                            <v-icon left> mdi-card-off </v-icon>
                            No definida
                          </v-chip>
                        </template>
                        <template v-slot:[`item.statu`]="{ item }">
                          <v-chip v-if="item.credential != null &&
                            item.credential.status_id == 1
                            " class="ma-2 text-left" color="success" outlined>
                            <v-icon left> mdi mdi-book-check-outline</v-icon>
                            {{ tem.credential.status_id }}
                          </v-chip>
                          <v-chip v-if="item.credential != null &&
                            item.credential.status_id == 2
                            " class="ma-2 text-left" color="primary" outlined>
                            <v-icon left> mdi mdi-book-check-outline</v-icon>
                            {{ item.credential.status_id }}
                          </v-chip>

                          <v-chip v-if="item.credential == null" class="ma-2 text-left" color="red" outlined>
                            <v-icon left> mdi-card-off </v-icon>
                            No definido
                          </v-chip>
                        </template>
                        <template v-slot:[`item.start_date`]="{ item }">
                          <spam v-if="item.credential != null">
                            {{ item.credential.start_date }}
                          </spam>
                          <v-chip v-if="item.credential == null" class="ma-2 text-left" color="red" outlined>
                            <v-icon left> mdi-card-off </v-icon>
                            No definida
                          </v-chip>
                        </template>
                        <template v-slot:[`item.end_date`]="{ item }">
                          <spam v-if="item.credential != null">
                            {{ item.credential.end_date }}
                          </spam>
                          <v-chip v-if="item.credential == null" class="ma-2 text-left" color="red" outlined>
                            <v-icon left> mdi-card-off </v-icon>
                            No definida
                          </v-chip>
                        </template>
                      </v-data-table></v-col>
                    <v-col cols="1"></v-col>
                  </v-row>
                </div>
              </vue-html2pdf>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import axios from "axios";
import VueSnotify from "vue-snotify";
import SnotifyMixin from "@/mixins/DisplayNotificationMixin.vue";
import html2pdf from "html2pdf.js";
import VueHtml2pdf from "vue-html2pdf";
import * as XLSX from "xlsx";

export default {
  mixins: [SnotifyMixin],
  VueSnotify,
  components: {
    VueHtml2pdf,
  },

  data() {
    return {
      visible: false,
      drawer: true,
      dialog: false,
      search: "",
      cont: 0,
      access: "",
      accesses: [],
      dates: ["", ""],
      typePerson: "",
      data: {},
      total_entrances: 0,
      total_exits: 0,
      rut: "",
      person: "",
      grade: "",
      grades: [],
      grade_name: "",

      headers: [
        { text: "RUT", value: "rut" },
        { text: "Nombre", value: "name" },
        { text: "Tipo Credencial", value: "credential" },
        { text: "Estado", value: "statu" },
        { text: "Fecha Inicial", value: "start_date" },
        { text: "Fecha Final", value: "end_date" },
      ],

      state: "",
      actions: [],
      registers: [],
      status: [],
      students: [],
      credencial_types: [],

      rutRules: [
        (v) => !!v || "El RUT es requerido",
        (v) =>
          (v && v.length <= 10) || "El RUT debe tener menos de 11 caracteres",
      ],
    };
  },

  mounted() {
    this.action = "Eliminar";
    this.getGradesCredentials();
  },

  methods: {

    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    generateReport() {
      this.$refs.html2Pdf.generatePdf();
    },
    exportToExcel() {
      const worksheet = XLSX.utils.table_to_sheet(this.$refs.html2Pdf.$el);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet 1");
      XLSX.writeFile(workbook, "report.xlsx");
    },

    generateTableContent() {
      const tableRows = [];

      // Agrega las filas de la tabla
      for (let i = 0; i < this.credencials.length; i++) {
        const row = [];
        // Agrega las celdas de la fila
        for (let j = 0; j < this.headers.length; j++) {
          const key = this.headers[j].value;
          const value = this.credencials[i][key];
          row.push(value);
        }
        tableRows.push(`<tr><td>${row.join("</td><td>")}</td></tr>`);
      }
      return `<table><thead><tr><th>${this.headers
        .map((header) => header.text)
        .join("</th><th>")}</th></tr></thead><tbody>${tableRows.join(
          ""
        )}</tbody></table>`;
    },

    exportTableToPdf() {
      const tableContent = this.generateTableContent();
      const element = document.createElement("div");
      element.innerHTML = tableContent;

      html2pdf(element, {
        margin: 1,
        filename: "tabla.pdf",
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { dpi: 192, letterRendering: true },
        jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
      });
    },

    getAccessById(access_id) {
      let access = this.accesses.find((a) => a.id == access_id);
      return access.name;
    },
    getActionById(action_id) {
      let action = this.actions.find((a) => a.id == action_id);
      return action.name;
    },
    getStatusById(id) {
      let statu = this.status.find((s) => s.id == id);
      return statu.name;
    },

    getGradesCredentials() {
      axios
        .get("/grade-index")
        .then((response) => {
          this.grades = response.data;
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification(
            "error",
            "Error",
            "Error al obtener las credenciales"
          );
        });
    },

    getActions() {
      axios
        .get("/action-index")
        .then((response) => {
          this.actions = response.data;
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification(
            "error",
            "Error",
            "Error al obtener las credenciales"
          );
        });
    },

    getStatus() {
      axios
        .get("/status-index")
        .then((response) => {
          this.status = response.data;
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification(
            "error",
            "Error",
            "Error al obtener los estados"
          );
        });
    },
    getAccess() {
      axios
        .get("/entrance-index")
        .then((response) => {
          this.accesses = response.data;
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification(
            "error",
            "Error",
            "Error al obtener los estados"
          );
        });
    },

    getInformAccess() {
      this.data.id = this.grade;

      axios
        .post("/information-credential-by-grade", this.data)
        .then((response) => {
          console.log("imprime resultado de busqueda");
          console.log(response.data.result);
          this.registers = response.data.result;
          this.status = response.data.status;
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification(
            "error",
            "Error",
            "Error al obtener los alumnos"
          );
        });
    },

    deleteItem(item) {
      console.log(item);
      let request = {
        id: item.id,
      };
      axios
        .post("/credential-destroy", request)
        .then(() => {
          this.displayNotification("success", "Éxito", "Credencial Eliminada");
          this.getCredencials();
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification(
            "error",
            "Error",
            "No se pudo eliminar la credencial"
          );
        });
    },
  },
};
</script>
