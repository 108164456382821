<template>
  <div class="home">
    <div class="text-overline ml-2"> Dashboard</div>
    <v-row>
      <v-col cols="3">
        <v-layout align-center justify-center>
          <v-flex>
            <v-card class="elevation-3 rounded-lg">
              <v-toolbar id="bar-login" style="height: 45px;" dark color="light-blue">
              </v-toolbar>

              <v-card class="rounded-lg" style="z-index:2;margin-top: -20px; border:0px">
                <v-card-text>
                  <div>
                    <v-row>
                      <v-col cols="10">
                        <v-row>
                          <v-col cols="12">
                            <spam  class="text-overline"> Total Alumnos
                            <div class="text-h3 font-weight-bold" style="color:#2b2b2d;">
                              {{ total_students }}
                            </div>
                          </spam>
                            <p> <strong>{{ total_students_inside }}</strong> Dentro de la Institución</p>
                          </v-col>                      
                         
                        </v-row>                     
                      </v-col>

                      <v-col cols="2"><v-btn icon color="light-blue">
                          <v-icon>mdi-account-group</v-icon>
                        </v-btn></v-col>
                    </v-row>
                  </div>
                </v-card-text>

              </v-card>

              <v-toolbar dark color="light-blue" style="margin-top: -30px; height: 70px;">

                <div class="mt-8">
                  <v-icon>mdi-update</v-icon>Actualizado recientemente
                </div>
              </v-toolbar>
            </v-card>
          </v-flex>
        </v-layout>
      </v-col>
      <v-col cols="3">
        <v-layout align-center justify-center>
          <v-flex>
            <v-card class="elevation-3 rounded-lg">
              <v-toolbar id="bar-login" style="height: 45px;" dark color="cyan">
              </v-toolbar>

              <v-card class="rounded-lg" style="z-index:2;margin-top: -20px; border:0px">
                <v-card-text>
                  <div class="">
                    <v-row>
                          <v-col cols="10">
                            <spam  class="text-overline"> Total de Funcionarios
                            <div class="text-h3 font-weight-bold" style="color:#2b2b2d;">
                              {{ total_officials }}
                            </div>
                          </spam>
                            <p> <strong> {{ total_officials_inside }}</strong> Dentro de la Institución</p>
                          </v-col>                     
                         
                      
                      <v-col cols="2"><v-btn icon color="cyan">
                          <v-icon>mdi-account-tie</v-icon>
                        </v-btn></v-col>
                    </v-row>
                  </div>
                </v-card-text>
              </v-card>
              <v-toolbar dark color="cyan" style="margin-top: -30px; height: 70px;">
                <div class="mt-8">
                  <v-icon>mdi-update</v-icon>Actualizado recientemente
                </div>
              </v-toolbar>
            </v-card>
          </v-flex>
        </v-layout>
      </v-col>
      <v-col cols="3">
        <v-layout align-center justify-center>
          <v-flex>
            <v-card class="elevation-3 rounded-lg">
              <v-toolbar id="bar-login" style="height: 45px;" dark color="amber">
              </v-toolbar>

              <v-card class="rounded-lg" style="z-index:2;margin-top: -20px; border:0px">
                <v-card-text>
                  <div>
                    <v-row>
                          <v-col cols="10">
                            <spam  class="text-overline"> Total de Visitantes
                            <div class="text-h3 font-weight-bold" style="color:#2b2b2d;">
                              {{ total_visit }}
                            </div>
                          </spam>
                            <p> <strong>  {{ total_visits_inside }}</strong> Dentro de la Institución</p>
                          </v-col>   
                      <v-col cols="2"><v-btn active icon color="amber">
                          <v-icon>mdi-account-arrow-right</v-icon>
                        </v-btn></v-col>
                    </v-row>
                  </div>
                </v-card-text>
              </v-card>
              <v-toolbar dark color="amber" style="margin-top: -30px; height: 70px;">
                <div class="mt-8">
                  <v-icon>mdi-update</v-icon>Actualizado recientemente
                </div>
              </v-toolbar>
            </v-card>
          </v-flex>
        </v-layout>
      </v-col>
      <v-col cols="3">
        <v-layout align-center justify-center>
          <v-flex>
            <v-card class="elevation-3 rounded-lg">
              <v-toolbar id="bar-login" style="height: 45px;" dark color="indigo ">
              </v-toolbar>
              <v-card class="rounded-lg" style="z-index:2;margin-top: -20px; border:0px">
                <v-card-text>
                  <div class="">
                    <v-row>
                          <v-col cols="10">
                            <spam  class="text-overline"> Total de Personas
                            <div class="text-h3 font-weight-bold" style="color:#2b2b2d;">
                              {{ total_students + total_officials + total_visit }}
                            </div>
                          </spam>
                            <p> <strong>   {{ total_students_inside + total_officials_inside + total_visits_inside }}</strong> Dentro de la Institución</p>
                          </v-col>   
                     
                      <v-col cols="2"><v-btn icon color="indigo ">
                          <v-icon>mdi-cog</v-icon>
                        </v-btn></v-col>
                    </v-row>
                  </div>


                </v-card-text>

              </v-card>

              <v-toolbar dark color="indigo" style="margin-top: -30px; height: 70px;">

                <div class="mt-8">
                  <v-icon>mdi-update</v-icon>Actualizado recientemente
                </div>
              </v-toolbar>
            </v-card>
          </v-flex>
        </v-layout>
      </v-col>
    </v-row>
  </div>
</template>


<script>
import axios from "axios";
import VueSnotify from "vue-snotify";
import SnotifyMixin from "@/mixins/DisplayNotificationMixin.vue";

export default {
  name: 'HomeView',
  mixins: [SnotifyMixin],
  VueSnotify,
  data() {
    return {
      total_students: 0,
      total_officials: 0,
      total_visit: 0,
      total_students_inside: 0,
      total_visits_inside: 0,
      total_officials_inside: 0,
    }
  },

  mounted() {
    this.getStatictic();
  },

  methods: {

    getStatictic() {
      axios
        .post("/information-total-student-official-visit")
        .then((response) => {
          this.total_students = response.data.total_students;
          this.total_students_inside = response.data.total_students_inside;
          console.log('total_students_inside');
          console.log(this.total_students_inside);
          this.total_officials = response.data.total_officials;
          this.total_officials_inside = response.data.total_officials_inside;
          console.log('total_officials_inside');
          console.log(this.total_officials_inside);
          this.total_visit = response.data.total_visit;
          this.total_visits_inside = response.data.total_visits_inside;
          console.log('total_visits_inside');
          console.log(this.total_visits_inside);
          console.log(response.data);

        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification(
            "error",
            "Error",
            "Error al cargar datos de la institución"
          );
        });
    },
  }
}

</script>
